import { IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonFooter, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import { chevronBackOutline, chevronForwardOutline, create, ellipsisHorizontal, ellipsisVertical, helpCircle, pause, personCircle, play, search, star } from 'ionicons/icons';
import { iMedia } from '../App';
import { RouteComponentProps } from 'react-router';
import { useEffect, useState } from 'react';

interface PageProps
  extends RouteComponentProps<{
    id: string;
  }> {
    media: iMedia[];
  }

const Player: React.FC<PageProps> = ({match, media,history}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [audio, setAudio] = useState<HTMLAudioElement>();

  const theMedia = media.find((m)=>m.link === match.params.id);

  const playpause = () => {
    setIsPlaying(!isPlaying);
  }

  useEffect(() => {
    if(!audio) return;

    if(isPlaying){
      audio.play();
    }else{
      audio.pause();
    }
    
  }, [audio, isPlaying]);

  useEffect(() => {
    if(theMedia){
      const theAudio = new Audio(theMedia?.audio);
      theAudio.onended = (ev) => { setIsPlaying(false)};
      setAudio(theAudio);
    }
    
  }, [theMedia]);

  const gottoNext = () => {
    audio && audio.pause();
    history.push(`/${theMedia?.next}`);
  };

  const gottoPrevious = () => {
    audio && audio.pause();
    history.push(`/${theMedia?.previous}`);
  };

  return theMedia ? (
    <IonPage>
      <IonContent fullscreen>
        <img src={theMedia.image} style={{width: '100%',height:'100%',objectFit:'contain'}}/>
      </IonContent>
      <IonFab slot="fixed" horizontal="center" vertical="bottom">
      <IonFabButton onClick={playpause}>
        <IonIcon icon={isPlaying ? pause : play}></IonIcon>
      </IonFabButton>
    </IonFab>
      <IonFooter>
      <IonToolbar>
        {theMedia.previous && 
        <IonButtons slot="start" >
          <IonButton onClick={gottoPrevious}>
              <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
            </IonButton>
          </IonButtons>}
          {theMedia.next && 
          <IonButtons slot="end">
          <IonButton onClick={gottoNext}>
              <IonIcon slot="icon-only" icon={chevronForwardOutline}></IonIcon>
            </IonButton>
          </IonButtons>}
      </IonToolbar>
    </IonFooter>
    </IonPage> 
  ) : <></>;
};

export default Player;
