import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Player from './pages/Player';

setupIonicReact();

export interface iMedia{
  link: string;
  title: string;
  audio: string;
  image: string; 
  next?: string;
  previous?: string;
}

const media: iMedia[] = [
  {
    link: 'caesar',
    title: 'Caesar Augustus',
    audio: '/assets/story/Caesar.mp3',
    image: '/assets/story/Story-Narration-01.jpg',
    next: 'scribe'
  },
  {
    link: 'scribe',
    title: 'Census Taker',
    audio: '/assets/story/Scribe.mp3',
    image: '/assets/story/Story-Narration-02.jpg',
    next: 'elizabeth',
    previous: 'caesar'
  },
  {
    link: 'elizabeth',
    title: 'Elizabeth',
    audio: '/assets/story/Elizabeth.mp3',
    image: '/assets/story/Story-Narration-03.jpg',
    next: 'innkeeper',
    previous: 'scribe'
  },
  {
    link: 'innkeeper',
    title: 'Innkeeper',
    audio: '/assets/story/Inn Keeper.mp3',
    image: '/assets/story/Story-Narration-04.jpg',
    next: 'shepherd',
    previous: 'elizabeth'
  },
  {
    link: 'shepherd',
    title: 'Shepherd',
    audio: '/assets/story/Shepherd.mp3',
    image: '/assets/story/Story-Narration-05.jpg',
    next: 'wisemen',
    previous: 'innkeeper'
  },
  {
    link: 'wisemen',
    title: 'Wise Men\'s Assistant',
    audio: '/assets/story/Wise Mans Assistant.mp3',
    image: '/assets/story/Story-Narration-06.jpg',
    previous: 'shepherd'
  },
];

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/:id" render={props => { return <Player {...props} media={media}  />}} />
        <Route exact path="/">
          <Redirect to="/caesar" />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
